// api.request.js
import HttpRequest from "./axios";
import { Url_Env } from '@/config/index';

console.log(process.env.NODE_ENV)
console.log(process.env.VUE_APP_FLAG)
let env = process.env.NODE_ENV === 'development' ? 'development' : process.env.VUE_APP_FLAG;
const BaseURL = Url_Env[env]
const axios = new HttpRequest(BaseURL);

console.log(BaseURL)

export default axios;
<template>
  <HeaderBar/>
  <router-view />
  <div id="subapp-viewport"></div>
</template>
<script>

import HeaderBar from '@/components/Header/Index';

export default {
  components: {
    HeaderBar
  }
};
</script>

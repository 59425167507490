// axios.js
import axios from "axios";
import { Message } from "view-ui-plus"; // 这里是引用了一个公共的弹框

class HttpRequest {
  constructor(baseUrl = baseURL) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }
  getInsideConfig() {
    return {
      baseURL: this.baseUrl
    };
  }
  destroy(url) {
    delete this.queue[url];
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }
  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      config => {
        // 添加全局的loading...
        if (!Object.keys(this.queue).length) {
          // Spin.show() // 不建议开启，因为界面不友好
        }
        this.queue[url] = true;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    // 响应拦截
    instance.interceptors.response.use(
      res => {
        this.destroy(url);
        const { data, status } = res;
        return { data, status };
      },
      error => {
        this.destroy(url);
        let errorInfo = error.response;
        if (!errorInfo) {
          const {
            request: { statusText, status },
            config
          } = JSON.parse(JSON.stringify(error));
          errorInfo = {
            statusText,
            status,
            request: { responseURL: config.url }
          };
        }
        this.addErrorLog(errorInfo);
        return Promise.reject(error);
      }
    );
  }
  addErrorLog(err) {
    // console.log(err);
    const { status } = err;
    switch (status) {
      case 510:
        // console.log(Message);
        Message.error("登录失效，请重新登录");
        break;
      case 500:
        Message.error(err.data);
        break;
      case 404:
        err.data.Message === "No Message available"
          ? Message.error("请求错误")
          : Message.error(err.data.Message);
        break;
      default:
        Message.error(err.data);
    }
  }
  request(options) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(options), options);
    this.interceptors(instance, options.url);
    return instance(options);
  }
}
export default HttpRequest;
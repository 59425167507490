/**
 * @param 处理SFA跳转逻辑
 * @returns {Boolean}
 */
export const getLocationUrlParams = () => {
  // 此处用于处理对接SFA跳转查看报表中心的逻辑
  let url = new URL(location.href);
  let params = new URLSearchParams(url.searchParams); 
  let origin_signature = params.get('origin_signature');
  let sessionName = sessionStorage.getItem('origin_signature');
  if (origin_signature) {
    sessionStorage.setItem('origin_signature', origin_signature);
  }
  if (origin_signature === 'SFA' || sessionName === 'SFA') {
    return true
  }
  return false
}
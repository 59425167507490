import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'view-ui-plus/dist/styles/viewuiplus.css';
import { registerMicroApps, start, setDefaultMountApp } from 'qiankun'
import microApps from './micro-app'


const app = createApp(App);
import { Form, FormItem, Input, Icon, Button, Card, Dropdown, DropdownMenu, DropdownItem, Row, Col, Modal } from 'view-ui-plus';
app.component('Form', Form);
app.component('FormItem', FormItem);
app.component('Input', Input);
app.component('Icon', Icon);
app.component('Button', Button);
app.component('Card', Card);
app.component('Dropdown', Dropdown);
app.component('DropdownMenu', DropdownMenu);
app.component('DropdownItem', DropdownItem);
app.component('Row', Row);
app.component('Col', Col);
app.component('Modal', Modal);



app.use(store).use(router).mount('#app')


// 定义loader方法，loading改变时，将变量赋值给App.vue的data中的isLoading
// function loader (loading) {
//   if (instance && instance.$children) {
//     // instance.$children[0] 是App.vue，此时直接改动App.vue的isLoading
//     instance.$children[0].isLoading = loading
//   }
// }

// 给子应用配置加上loader方法
const apps = microApps.map(item => {
  return {
    ...item,
    // loader
  }
})

registerMicroApps(apps, {
  beforeLoad: app => {
    console.log('before load app.name====>>>>>', app.name)
  },
  beforeMount: [
    app => {
      console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
    }
  ],
  afterMount: [
    app => {
      console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name)
    }
  ],
  afterUnmount: [
    app => {
      console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
    }
  ]
})

console.log('123456++++++++++++')
// setDefaultMountApp('/productBackend')
start()

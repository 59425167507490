<template>
  <Modal class="modals" v-model="modalP" :footer-hide="true" :mask-closable="false" title="修改密码" width="600">
    <Form ref="baseFromPwd" :model="pwdForm" :rules="dataRules" :label-width="100">
      <Row>
        <Col span="24">
          <FormItem label="原始密码:" prop="oldPassword">
            <Input style="width:400px;" type="password" autocomplete="new-password" v-model="pwdForm.oldPassword" placeholder="请输入" ></Input>
          </FormItem>
        </Col>
        <Col span="24">
          <FormItem label="新密码:" prop="password">
            <Input style="width:400px;" type="password" autocomplete="new-password" v-model="pwdForm.password" placeholder="请输入" ></Input>
          </FormItem>
        </Col>
        <Col span="24">
          <FormItem label="确认密码:" prop="confirmPassword">
            <Input style="width:400px;" type="password" autocomplete="new-password" v-model="pwdForm.confirmPassword" placeholder="请输入" ></Input>
          </FormItem>
        </Col>
        <Col span="24">
          <p class="message-title">*忘记原始密码找管理员重置密码</p>
        </Col>
      </Row>
    </Form>
    <div slot="footer" style="text-align:center">
      <Row type="flex" justify="center">
        <Button style="margin-right:30px" @click="cancel">关闭</Button>
        <Button type="primary" :loading="btnLoading" :disabled="btnLoading" @click="submitForm">
          <span v-if="!btnLoading">确认</span>
          <span v-else>加载中...</span>
        </Button>
      </Row>
    </div>
  </Modal>
</template>
<script>
import { updatePassword } from '@/api/user';
import { Message } from "view-ui-plus";
import { reactive, toRefs, ref } from 'vue';
import { Domain_Url_Env } from '@/config/index';
// 密码须为8～20位字母数字特殊字符组合，特殊字符包括_~!@#$%^&*(),./?
const passwordReg = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[_~!@#$%^&*(),./?])[\da-zA-Z_~!@#$%^&*(),./?]{8,20}$/;

export default {
  setup() {
    const baseFromPwd = ref(null);

    let dataMap = reactive({
      modalP: false,
      btnLoading: false,
      pwdForm: {
        oldPassword: '',
        password: '',
        confirmPassword: ''
      },
    })

    const validatePassword = (rule, value, callback) => {
      if (value) {
        if (!passwordReg.test(value)) {
          callback(new Error('密码须为8～20位字母数字特殊字符组合，特殊字符包括_~!@#$%^&*(),./?'))
        } else {
          callback()
        }
      } else {
        callback(new Error('必填'))
      }
    }
    const validateConfirm = (rule, value, callback) => {
      if (value) {
        if (dataMap.pwdForm.password != value) {
          callback(new Error('两次输入的密码不一致'))
        } else {
          callback()
        }
      } else {
        callback(new Error('必填'))
      }
    }
    const dataRules = reactive({
      oldPassword: [
        { required: true, message: "请填写", trigger: 'blur' }
      ],
      password: [
        { required: true, validator: validatePassword, trigger: 'blur' }
      ],
      confirmPassword:  [
        { required: true, validator: validateConfirm, trigger: 'blur' }
      ]
    })

    function cancel() {
      dataMap.modalP = false;
      dataMap.pwdForm = {
        oldPassword: '',
        password: '',
        confirmPassword: ''
      }
      baseFromPwd.value.resetFields();
    }
    function submitForm() {
      baseFromPwd.value.validate().then((valid) => {
        console.log(valid)
        if (valid) {
          const userInfo = JSON.parse(localStorage.getItem('USERINFOSTORAGE'));
          const userId = userInfo.user.userId;
          const params = {
            ...dataMap.pwdForm,
            userId: userId
          }
          dataMap.btnLoading = true;
          updatePassword(params).then(res => {
            if (res.data.code === 0) {
              cancel();
              Message.success({
                content: '修改成功',
                onClose: () => {
                  Logout()
                }
              })
            } else {
              Message.warning(res.data.msg);
            }
            dataMap.btnLoading = false;
          })
        }
      })
    }

    function Logout() {
      const env = process.env.VUE_APP_FLAG;
      if (env === 'development') {
        window.location.href = 'http://localhost:10000/'
      } else {
        window.location.href = Domain_Url_Env[env];
      }
      localStorage.clear();
    }

    const open = () => {
      dataMap.modalP = true;
    }

    return {
      open,
      baseFromPwd,
      cancel,
      submitForm,
      dataRules,
      ...toRefs(dataMap)
    }
  }
}
</script>
<style lang="less" scoped>
.message-title {
  color: #999999;
  margin-left: 100px;
  margin-bottom: 40px;
  font-size: 12px;
}
</style>



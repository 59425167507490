<template>
  <div class="tabs" v-if="jumpOrigin !== 'SFA'">
    <div class="channel-drop">
      <Dropdown>
        <a href="javascript:void(0)">
          <img src="../../assets/avator.jpg" alt="" class="avator">
        </a>
        <template #list>
          <DropdownMenu>
            <DropdownItem v-for="item in optionList" :key="item.value" @click="tabsChange(item)">
              <Icon v-if="item.value === 0" type="ios-settings-outline" style="margin-right:5px;"/>
              <Icon v-else-if="item.value === -1" type="ios-log-out" style="margin-right:5px;" />
              <Icon v-else type="ios-list-box-outline" style="margin-right:5px;" />
              <span :class="item.value === -1 ? 'active-logout' : item.value === 0 ?  'active-pwd' : ''">{{item.label}}</span>
            </DropdownItem>
          </DropdownMenu>
        </template>
      </Dropdown>
      <Icon type="md-arrow-dropdown" />
    </div>
  </div>
  
  <SetPasswordModal ref="passwordModal"/>
</template>
<script>
import { logout } from '@/api/login';
import { Message } from "view-ui-plus";
import { Domain_Url_Env, ZWProfitBackend_Domain_Url_Env } from '@/config/index';
import SetPasswordModal from './components/SetPassword.vue';
import { ref } from 'vue';
import { getLocationUrlParams } from '@/libs/util';

export default {
  components: {
    SetPasswordModal
  },
  setup() {
    // 此处用于处理对接SFA跳转查看报表中心的逻辑
    let jumpOrigin = ref('');
    const isSfa = getLocationUrlParams();
    if (isSfa) {
      jumpOrigin.value = 'SFA'
    }

    const optionList = [
      {
        value: 1,
        label: '商品模块'
      },
      {
        value: 2,
        label: '订单模块'
      },
      {
        value: 4,
        label: '造旺收益中台'
      },
      {
        value: 0,
        label: '修改密码'
      },
      {
        value: -1,
        label: '退出登录'
      }
    ]
    
    function toProductBackend() {
      history.pushState(null, "productBackend", "/productBackend")
    }
    function toOrderBackend() {
      history.pushState(null, "orderBackend", "/orderBackend")
    }

    const toRoute = (val) => {
      if (val === -1) {
        // 退出登录
        console.log('退出登录')
        LogOut();
        return
      }
      if (val === 0) {
        // 修改密码
        console.log('修改密码')
        ResetPassword();
        return
      }

      const permissions = localStorage.getItem('MODULES_PERMISSIONS');
      if (!permissions.includes(val)) {
        Message.warning('无权限');
        return
      }
      if (val === 1) {
        toProductBackend()
      } else if (val === 2) {
        toOrderBackend()
      } else if (val === 4) { // 跳转造旺收益中台
        const env = process.env.VUE_APP_FLAG;
        const url = ZWProfitBackend_Domain_Url_Env[env];
        window.open(url)
      }
    }
    // 退出登录
    function LogOut() {
      const token = localStorage.getItem('TOKEN');
      logout(token).then(() => {
        const env = process.env.VUE_APP_FLAG;
        if (env === 'development') {
          window.location.href = 'http://localhost:10000/'
        } else {
          window.location.href = Domain_Url_Env[env];
        }
        localStorage.clear();
      })
    }

    const passwordModal = ref(null);
    const ResetPassword = () => {
      passwordModal.value.open();
    }

    function tabsChange(val) {
      console.log(val)
      toRoute(val.value)
    }

    return {
      optionList,
      tabsChange,
      passwordModal,
      jumpOrigin
    };
  },
};
</script>
<style lang="less">
html, body {
  margin: 0;
  padding: 0;
}

.tabs {
  position: absolute;
  top: 10px;
  right: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  z-index: 9;
}
.channel-drop {
  display: flex;
  align-items: center;
  .channel-name {
    margin-right: 10px;
    font-size: 16px;
    color: #fc556c;
  }
}
.avator {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 3px;
}
.active-logout {
  color: #ff6b7a;
}
.active-pwd {
  color: #2d8cf0;
}
</style>

// 不同环境下的接口地址
export const Url_Env= {
  'development': 'https://dev-api.hotkidceo.com',
  'test': 'https://dev-api.hotkidceo.com',
  'pre': 'https://stg-srv.hotkidceo.com',
  'production': 'https://srv.hotkidceo.com',
}
// 不同环境的项目地址
export const Domain_Url_Env= {
  'development': 'http://dev-op.hotkidceo.com',
  'test': 'http://dev-op.hotkidceo.com',
  'pre': 'https://stg-op.hotkidceo.com',
  'production': 'https://op.hotkidceo.com',
}
// 造旺收益中台项目地址
export const ZWProfitBackend_Domain_Url_Env= {
  'development': 'http://dev-op.hotkidceo.com/zwProfitBackend/login',
  'test': 'http://dev-op.hotkidceo.com/zwProfitBackend/login',
  'pre': 'https://stg-op.hotkidceo.com/zwProfitBackend/login',
  'production': 'https://srv.hotkidceo.com/ceo/zwProfitBackend/login',
}
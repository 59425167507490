import axios from "@/libs/api.request";

// 登录
export const login = (data) => {
  return axios.request({
    url: "/op/login",
    method: "post",
    data: data
  });
};
// 查询用户信息
export const getUserInfo = (token) => {
  return axios.request({
    url: '/op/getInfo',
    method: 'post',
    headers: {
      Authorization: token
    }
  });
}
// 退出登录
export const logout = (token) => {
  return axios.request({
    url: '/ceo/admin/product/logout ',
    method: 'get',
    headers: {
      Authorization: token
    }
  })
}